import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  fetchUserLevel,
  fetchChatHistory,
  useSendMessage,
} from "./utils/chatHelper";
import api from "../../utils/api";
import { useAuth } from "../../utils/AuthProvider";
import SideBar from "../../components/SideBar/SideBar";
import Spinner from "../../components/Spinner/Spinner";
import Header from "./components/HeaderUserdashboard";
import MessageList from "./components/MessageList";
import MessageInput from "./components/MessageInput";
import PredefinedQuestions from "./components/PredefinedQuestions";
import questions from "./utils/questions.json";
import { encode } from "gpt-tokenizer";
import Payments from "./components/Payments";
import Profile from "./components/Profile";

let cachedMessages = null;

export default function UserDashboard() {
  const { logout, token } = useAuth();
  const [threadId, setThreadId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userLevel, setUserLevel] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [didSearch, setDidSearch] = useState(false);
  const [linkSearch, setLinkSearch] = useState(false);
  const [data, setData] = useState();
  const [wait, setWait] = useState(false);
  const [runId, setRunId] = useState();
  const [messageId, setMessageId] = useState();

  const fetchMessagesRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const eventSourceRef = useRef(null);

  useEffect(() => {
    if (token) {
      fetchUserLevel(token, setUserLevel);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchChatHistory(token, setThreadId, setRefresh);
    }
  }, [token]);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeModalProf = () => setModalOpenProfile(false);

  const fetchThreadMessages = useCallback(
    async (threadId) => {
      const cachedMessages = JSON.parse(
        localStorage.getItem(`messages_${threadId}`)
      );
      if (cachedMessages) {
        setLoading(false);
        setTimeout(() => {
          setMessages(cachedMessages);
        }, 100);

        return;
      }

      setLoading(true);
      try {
        const response = await api.get(`/chat/history/thread/${threadId}`);
        const fetchedMessages = response.data;

        if (fetchedMessages && fetchedMessages.length > 0) {
          setMessages((prevMessages) => {
            const newMessages = fetchedMessages.filter(
              (msg) =>
                !prevMessages.some((existingMsg) => existingMsg.id === msg.id)
            );
            const updatedMessages = [...prevMessages, ...newMessages];
            localStorage.setItem(
              `messages_${threadId}`,
              JSON.stringify(updatedMessages)
            );

            return updatedMessages;
          });
        } else {
          console.warn("No messages found for the thread.");
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    },
    [threadId]
  );

  useEffect(() => {
    const fetchWordCount = async () => {
      try {
        const response = await api.get(`/chat/token`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWordCount(response.data?.chat_token || 0);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    if (token) {
      fetchWordCount();
    }
  }, [isLoading]);

  // const stopStream = () => {
  //   if (eventSourceRef.current) {
  //     eventSourceRef.current.close(); // Close the stream
  //     console.log("Stream stopped by user.");
  //     setIsLoading(false); // Stop the loading indicator
  //     setHasError(false); // Optionally reset any error state
  //   }
  // };

  // const handleRegenerate = () => {
  //   const lastMessage = messages[messages.length - 2]?.text_value || "";
  //   const tokenCount = encode(lastMessage).length;
  //   const lastFile = fileInputRef.current?.files[0] || null;
  //   handleSendMessage(lastMessage, lastFile, "user", tokenCount);
  // };

  const { handleSendMessage } = useSendMessage(
    token,
    threadId,
    setMessages,
    setIsLoading,
    setHasError,
    setWait,
    setDidSearch,
    setLinkSearch,
    setData,
    setNewMessage,
    runId,
    setRunId,
    messageId,
    setMessageId
  );
  const handlePredefinedQuestionClick = (question) => {
    const tokenCount = encode(question).length;
    if (
      wordCount <= 5000 ||
      userLevel === "premium" ||
      userLevel === "employee"
    ) {
      handleSendMessage(question, null, "user", tokenCount);
    }
  };

  useEffect(() => {
    if (threadId) {
      setLinkSearch(false);
      setData(null);
      fetchThreadMessages(threadId);
    }
  }, [threadId, fetchThreadMessages]);

  const handleUpload = () => {
    if (userLevel === "premium" || userLevel === "employee") {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // if (file) {
    //   console.log("Selected file:", file.name);
    // }
  };

  return (
    <div className="flex h-screen text-black">
      {/* Sidebar */}
      <div
        className={`flex flex-col h-screen ${
          sidebarVisible ? "sm:w-64" : "w-0"
        } transition-width duration-500`}
      >
        <div
          className={`h-full sm:w-64 w-48 bg-white transform transition-transform duration-500 z-30 ${
            sidebarVisible ? "translate-x-0" : "-translate-x-full"
          } sm:relative fixed`}
        >
          <div className="flex flex-col h-screen overflow-y-auto ">
            {sidebarVisible && (
              <SideBar
                key={refresh}
                userLevel={userLevel}
                token={token}
                threadId={threadId}
                setThreadId={setThreadId}
                setMessages={setMessages}
                sidebarVisible={sidebarVisible}
                setSidebarVisible={setSidebarVisible}
                wait={wait}
                openModal={openModal}
                closeModal={closeModal}
                setModalOpenProfile={setModalOpenProfile}
              />
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-grow h-screen w-screen ">
        {/* Header */}
        <div className="sticky top-0 z-20 bg-white border-b border-gray-200 shadow-sm">
          <Header
            userLevel={userLevel}
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            logout={logout}
            wordCount={wordCount}
            openModal={openModal}
          />
        </div>

        {/* Content Area */}
        <div className="flex flex-col flex-grow h-screen overflow-y-auto">
          <div className="p-4 flex-grow overflow-auto border-r border-gray-300 height-calc">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <MessageList
                  messages={messages}
                  setMessages={setMessages}
                  isLoading={isLoading}
                  messagesEndRef={messagesEndRef}
                  hasError={hasError}
                  setHasError={setHasError}
                  didSearch={didSearch}
                  linkSearch={linkSearch}
                  data={data}
                  threadId={threadId}
                  messageId={messageId}
                  setMessageId={setMessageId}
                  handleSendMessage={handleSendMessage}
                  // handleRegenerate={handleRegenerate}
                  // stopStream={stopStream}
                />
                {messages.length === 0 && !loading && (
                  <PredefinedQuestions
                    predefinedQuestions={questions}
                    handlePredefinedQuestionClick={
                      handlePredefinedQuestionClick
                    }
                  />
                )}
              </>
            )}
          </div>
          <MessageInput
            userLevel={userLevel}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSendMessage={handleSendMessage}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            handleUpload={handleUpload}
            setModalOpen={setModalOpen}
            isLoading={isLoading}
            wait={wait}
            messageWordCount={wordCount}
            runId={runId}
            threadId={threadId}
          />
        </div>
      </div>

      <Payments
        userLevel={userLevel}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <Profile
        userLevel={userLevel}
        modalOpen={modalOpenProfile}
        closeModal={closeModalProf}
        logout={logout}
        user={userLevel}
      />
    </div>
  );
}