import { useState, useEffect } from "react";
import triviaData from "../../utils/trivia.json";

const Spinner = () => {
  const [randomFact, setRandomFact] = useState("");

  const getRandomFact = () => {
    const facts = Object.values(triviaData.trivia);
    const randomIndex = Math.floor(Math.random() * facts.length);
    return facts[randomIndex];
  };

  useEffect(() => {
    setRandomFact(getRandomFact());
  }, []);
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="relative w-24 h-24">
        <div className="absolute inset-0 rounded-full border-8 border-green-500 border-t-transparent border-l-transparent animate-spin"></div>
        <div className="absolute inset-0 rounded-full animate-pulse opacity-75"></div>
      </div>

      <p className="mt-4 text-xs text-center text-gray-500">
        {randomFact.fact}
      </p>
    </div>
  );
};

export default Spinner;
