import React, { useState, useEffect, useRef } from "react";
import {
  FaPaperclip,
  FaInfoCircle,
  FaTimes,
  FaMicrophone,
  FaArrowRight,
  FaStop,
} from "react-icons/fa";
import { encode } from "gpt-tokenizer";
import Gcip from "../../../utils/gcip";
import api from "../../../utils/api";
import { useAuth } from "../../../utils/AuthProvider";

const MessageInput = React.memo(
  ({
    userLevel,
    newMessage,
    setNewMessage,
    handleSendMessage,
    handleFileChange,
    fileInputRef,
    handleUpload,
    setModalOpen,
    messageWordCount,
    isLoading,
    wait,
    runId,
    threadId,
  }) => {
    const { token } = useAuth;

    const [showInfo, setShowInfo] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [tokenCount, setTokenCount] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showExceededWarning, setShowExceededWarning] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const textRef = useRef(null);

    const handleInputChange = (e) => {
      setNewMessage(e.target.value);
      textRef.current.style.height = "auto";
      textRef.current.style.height = `${Math.min(
        textRef.current.scrollHeight,
        125
      )}px`;
      updateTokenCount(e.target.value);
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      const maxFileSize = 25 * 1024 * 1024; // Maximum file size in bytes (25 MB)

      if (file && file.size <= maxFileSize) {
        setSelectedFile(file);
        handleFileChange(event);
      } else if (file.size > maxFileSize) {
        setShowErrorModal(true);
        event.target.value = null;
      }
    };

    const onSendClick = () => {
      if (isLoading || wait) {
        return;
      }
      if (
        newMessage.trim() !== "" &&
        (messageWordCount <= 5000 ||
          userLevel === "premium" ||
          userLevel === "employee")
      ) {
        setNewMessage("");
        setSelectedFile(null);
        handleSendMessage(newMessage, selectedFile, "user", tokenCount);
        fileInputRef.current.value = null;
        textRef.current.style.height = "50px";
      }
    };

    const updateTokenCount = (message) => {
      const tokens = encode(message);
      setTokenCount(tokens.length);
    };

    const handleCancel = async () => {
      try {
        const response = await api.post(
          `/chat/stream/cancel/${threadId}/run/${runId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      setShowExceededWarning(messageWordCount >= 5000);
    }, [messageWordCount]);

    return (
      <div className="pt-4 px-4 bg-gradient-to-r from-blue-50 to-green-50 border-t border-gray-200 rounded-lg shadow-md">
        {showExceededWarning && userLevel === "free" && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex items-center">
            <span className="block sm:inline text-xs font-medium">
              ⚠️ You've reached the 5000-token limit for your free account. To
              continue the conversation without interruptions, please{" "}
              <span
                className="cursor-pointer text-blue-600 underline"
                onClick={() => setModalOpen(true)}
              >
                upgrade to our premium plan.
              </span>
            </span>
          </div>
        )}

        <div className="flex items-center">
          <div className="relative w-full sm:max-w-auto">
            <textarea
              ref={textRef}
              className={`w-full p-3 mb-1 rounded-lg focus:outline-none focus:ring-2 resize-none text-sm pr-10 shadow-inner bg-white placeholder-gray-500 transition-all duration-200 ${
                showExceededWarning && userLevel === "free"
                  ? "border-red-500 focus:ring-red-500"
                  : "focus:ring-green-400"
              }`}
              placeholder="Type your message here... 💬"
              value={newMessage}
              onChange={handleInputChange}
              onPaste={(e) => {
                e.preventDefault();
                let text = (e.clipboardData || window.clipboardData).getData(
                  "text"
                );
                text = text.replace(/\n\s*\n/g, "");
                const cursorPosition = e.target.selectionStart;
                const value = e.target.value;
                e.target.value = value.slice(0, cursorPosition) + text;
                handleInputChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey && !isLoading) {
                  e.preventDefault();
                  onSendClick();
                }
              }}
              rows="1"
              style={{ maxHeight: "125px", overflow: "auto" }}
              disabled={showExceededWarning && userLevel === "free"}
            />
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={onFileChange}
              accept=".pdf,.docx,.pptx,.txt,.xlsx"
            />
            <button
              type="button"
              className="absolute top-[14px] right-3 sm:right-4 pb-1 flex items-center text-gray-500 hover:text-green-500"
              onClick={handleUpload}
              title="File Upload"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <FaPaperclip size={18} />
            </button>
            {showTooltip && (
              <div className="absolute bottom-10 right-2 sm:right-4 bg-gray-700 text-white text-xs p-2 rounded-md shadow-md">
                {userLevel !== "premium" && userLevel !== "employee"
                  ? "This feature is available for premium users only."
                  : "Files larger than 25 MB cannot be uploaded."}
              </div>
            )}
          </div>

          <button
            className={`px-3 ml-2 text-white rounded-lg shadow w-auto h-10 text-sm relative mb-2 ${
              wait
                ? "bg-red-400 hover:bg-red-500"
                : "bg-green-400 hover:bg-green-500"
            }`}
            onClick={wait ? handleCancel : onSendClick}
            title="Send/Stop Button"
            disabled={showExceededWarning && userLevel === "free"}
          >
            {wait ? (
              <FaStop size={12} />
            ) : newMessage.trim() === "" && !selectedFile ? (
              <FaArrowRight size={14} />
            ) : (
              <FaArrowRight size={14} />
            )}
          </button>
        </div>

        {selectedFile && (
          <div className="flex items-center mt-3 text-sm text-gray-700 bg-gray-100 p-2 rounded-lg shadow-inner">
            <p className="mr-2">📁 {selectedFile.name}</p>
            <FaTimes
              className="text-red-500 cursor-pointer hover:text-red-600"
              onClick={() => {
                setSelectedFile(null);
                fileInputRef.current.value = null;
              }}
            />
          </div>
        )}

        <div className="flex items-center justify-center text-xs text-gray-600 mb-1">
          <p className="text-center">
            Get better responses by being specific and clear in your queries.
          </p>
          <FaInfoCircle
            className="ml-2 text-gray-500 hover:text-green-500 cursor-pointer"
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
          />
          {showInfo && <Gcip />}
        </div>

        {showErrorModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-2xl font-bold text-red-500 mb-4">Error</h2>
              <p className="mb-4">
                File size exceeds 25 MB. Please upload a smaller file.
              </p>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                onClick={() => setShowErrorModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default MessageInput;
