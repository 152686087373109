import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import useClickOutside from "../../../hooks/useClickOutside";
import { FaGlobe } from "react-icons/fa";

const useLinkSearch = ({ data, index, message, messages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const buttonRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useClickOutside(dropdownRef, buttonRef, () => setIsOpen(false));

  return (
    <>
      {message.role === "assistant" && index === messages.length - 1 && (
        <div className="relative w-full max-w-2xl  p-4 mb-4">
          <div className="flex items-center justify-between mb-4">
            <span className="py-2 px-3 text-sm font-semibold text-white bg-green-500  rounded-md shadow-sm">
              Search Results
            </span>
          </div>

          <div className="flex overflow-x-auto space-x-4 w-full py-3 mb-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
            {data.map((item, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-40 bg-white shadow-lg rounded-lg border border-gray-100 p-3 transform transition-transform hover:scale-105 hover:shadow-xl duration-200"
              >
                <img
                  src={item.logo}
                  alt={`${item.display_link} logo`}
                  className="w-8 h-8 object-cover rounded-full mb-3"
                  onError={(e) => {
                    e.target.src = "browser.svg"; // Placeholder icon path
                  }}
                />
                <div className="flex flex-col text-xs">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 font-semibold hover:underline leading-tight"
                  >
                    {item.title.length > 40
                      ? item.title.slice(0, 40) + "..."
                      : item.title}
                  </a>
                  <span className="text-gray-400 text-xs mt-1">
                    {item.display_link}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default useLinkSearch;
