import React from "react";
import { FaCheckCircle } from "react-icons/fa";

export default function PaymentSuccess() {
  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-b from-green-50 to-green-100">
      <div className="bg-white shadow-xl rounded-xl p-8 max-w-lg w-full">
        {/* Icon */}
        <div className="flex justify-center mb-6">
          <FaCheckCircle className="text-green-500 text-7xl" />
        </div>

        {/* Title */}
        <h1 className="text-3xl font-semibold text-gray-800 mb-4 text-center">
          Payment Successful!
        </h1>

        {/* Description */}
        <p className="text-gray-600 text-lg text-center mb-8">
          Thank you! Your payment has been processed successfully. You can now
          access your dashboard.
        </p>

        {/* Button */}
        <div className="flex justify-center">
          <button
            onClick={() => (window.location.href = "/app")}
            className="bg-green-600 hover:bg-green-700 text-white font-medium py-3 px-6 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}
