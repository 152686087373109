import React, { useEffect, useState } from "react";

const TypewriterText = () => {
  const texts = [
    "Streamline payroll, taxes, and audits",
    "Keep your data secure with top-notch encryption",
    "Access to up-to-date knowledge base",
  ];

  const [currentText, setCurrentText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const typingSpeed = 100;
  const erasingSpeed = 50;
  const pauseDuration = 2000;

  useEffect(() => {
    let interval;

    if (isTyping) {
      if (charIndex < texts[textIndex].length) {
        interval = setInterval(() => {
          setCurrentText((prev) => prev + texts[textIndex][charIndex]);
          setCharIndex((prev) => prev + 1);
        }, typingSpeed);
      } else {
        clearInterval(interval);
        setIsPaused(true);
        setTimeout(() => {
          setIsPaused(false);
          setIsTyping(false);
        }, pauseDuration);
      }
    }

    // Erasing effect
    if (!isTyping && !isPaused) {
      if (charIndex > 0) {
        interval = setInterval(() => {
          setCurrentText((prev) => prev.slice(0, -1));
          setCharIndex((prev) => prev - 1);
        }, erasingSpeed);
      } else {
        clearInterval(interval);
        setTextIndex((prev) => (prev + 1) % texts.length);
        setCharIndex(0);
        setIsTyping(true);
      }
    }

    return () => clearInterval(interval);
  }, [charIndex, textIndex, isTyping, isPaused]);

  return (
    <div className="  text-white px-4 sm:px-6 py-2 sm:py-3 flex justify-center items-center  border-2 border-transparent  duration-300 ease-in-out hover:scale-105 group">
      <div className="flex items-center">
        <h3 className=" font-semibold text-white text-transparent bg-clip-text drop-shadow-lg group-hover:text-white transition-all duration-300 ease-in-out break-words whitespace-normal text-center">
          <span className="typewriter">{currentText || "\u00A0"}</span>
        </h3>
      </div>
    </div>
  );
};

export default TypewriterText;
