const gcip = () => {
  return (
    <div className="absolute bottom-10 right-[2%] lg:right-[20%] bg-white border border-gray-300 rounded-md p-4 shadow-lg w-72 sm:w-96 text-xs z-40 transition-transform duration-200 ease-in-out transform translate-y-2">
      <div className="space-y-3 text-gray-700">
        <div>
          <strong>Goal:</strong> Clearly state what you want to achieve with
          your prompts.
          <p className="text-gray-600 italic">
            Example: "I need help with financial ratios for my quarterly
            report."
          </p>
        </div>
        <div>
          <strong>Context:</strong> Provide background information to help the
          bot understand your needs.
          <p className="text-gray-600 italic">
            Example: "I'm preparing taxes for a small business using cash-basis
            accounting."
          </p>
        </div>
        <div>
          <strong>Instruction:</strong> Include details or data to make the
          response accurate.
          <p className="text-gray-600 italic">
            Example: "Provide citations and legal references related to business
            taxation in the Philippines."
          </p>
        </div>
        <div>
          <strong>Presentation:</strong> Organize your input clearly to avoid
          confusion.
          <p className="text-gray-600 italic">
            Example: "I'm preparing for an audit and need help with:
          </p>
          <ul className="list-decimal pl-3 text-gray-600 italic">
            <li>Reviewing accounts receivable balances for accuracy.</li>
            <li>Verifying the completeness of expense reports.</li>
            <li>
              Ensuring proper classification of capital and operational
              expenses. Can you provide guidance on these areas?
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default gcip;
