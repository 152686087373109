import PhoneInput from "react-phone-input-2";
import { ReactComponent as Loader } from "../../../assets/loader.svg";

export default function Billing({
  loading,
  handleSubscribe,
  handlePhoneChange,
  isButtonDisabled,
  phoneNumber,
  selectedPlan,
  setSelectedPlan,
}) {
  const planDetails =
    selectedPlan === "monthly"
      ? { price: 16.99, label: "BETH Premium - Monthly", term: "per month" }
      : { price: 203.88, label: "BETH Premium - Annually", term: "per year" };

  const discountedPrice =
    selectedPlan === "annual"
      ? planDetails.price * (1 - 0.20)
      : planDetails.price;

  const discountEarly = 2.0;
  return (
    <form
      onSubmit={handleSubscribe}
      className="max-w-xl mx-auto mt-10 bg-white shadow-lg rounded-lg p-6"
    >
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">
          Subscribe to BETH Premium
        </h2>
        <p className="text-gray-500 mt-2">
          {selectedPlan === "monthly" ? "Billed monthly" : "Billed annually"}
        </p>
      </div>

      {/* Plan Selection */}
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <h3 className="text-lg font-bold text-gray-700 mb-4">
          Choose Your Plan
        </h3>
        <div className="flex justify-center space-x-4">
          <button
            type="button"
            className={`px-4 py-2 rounded-lg text-lg font-semibold border-2 ${selectedPlan === "monthly"
              ? "bg-green-500 text-white"
              : "border-gray-300"
              }`}
            onClick={() => setSelectedPlan("monthly")}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-lg text-lg font-semibold border-2 ${selectedPlan === "annual"
              ? "bg-green-500 text-white"
              : "border-gray-300"
              }`}
            onClick={() => setSelectedPlan("annual")}
          >
            Annual
          </button>
        </div>
      </div>

      {/* Billing Information */}
      <div className="mb-6">
        <h3 className="text-lg font-bold text-gray-700">Billing Information</h3>

        <label htmlFor="email" className="block text-gray-700 mt-4">
          Email
        </label>
        <input
          type="email"
          id="email"
          className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
          required
        />

        <label htmlFor="firstName" className="block text-gray-700 mt-4">
          First Name
        </label>
        <input
          type="text"
          id="firstName"
          className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
          required
        />

        <label htmlFor="lastName" className="block text-gray-700 mt-4">
          Last Name
        </label>
        <input
          type="text"
          id="lastName"
          className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
          required
        />

        <label htmlFor="phone" className="block text-gray-700 mt-4">
          Phone
        </label>
        <PhoneInput
          inputProps={{
            id: "phoneNumber",
            name: "phoneNumber",
            placeholder: "Enter phone number",
          }}
          country={"ph"}
          required
          value={phoneNumber}
          onChange={handlePhoneChange}
          countryCodeEditable={false}
          containerClass="w-full"
          inputClass="w-full p-3 border-2 rounded-lg my-2 text-gray-800 focus:ring-2 focus:ring-green-500"
        />
      </div>

      <h3 className="text-lg font-bold text-gray-700 mb-4">Billing Details</h3>
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <div className="flex justify-between py-2">
          <p className="text-gray-700">{planDetails.label}</p>
          <p className="font-semibold text-gray-800">
            ${planDetails.price}
            {/* {selectedPlan === "annual" && (
              <span className="text-green-600">
                ${discountedPrice.toFixed(2)} {planDetails.term}
              </span>
            )}
            {selectedPlan === "monthly" && planDetails.term} */}
          </p>
        </div>

        <div className="py-2">
          <div className="flex justify-between items-center">
            <p className="">Discount</p>
            <p className="font-semibold text-gray-800">
              {selectedPlan === "annual"
                ? `- $${(planDetails.price * 0.15).toFixed(2)}`
                : `- $${discountEarly}`}
            </p>
          </div>

          <div className="ml-4">
            <sub className="text-xs text-gray-500">
              <p className="text-xs text-gray-500">
                {selectedPlan === "annual" ? (
                  <>
                    <span>Annual Discount (-15%)</span>
                    <br />
                  </>
                ) : (
                   <span></span>
                )}
              </p>
            </sub>
          </div>
        </div>

        {/* )} */}

        <div className="flex justify-between py-2">
          <p className="text-gray-700">Subtotal</p>
          <p className="font-semibold text-gray-800">
            $
            {selectedPlan === "annual"
              ? discountedPrice.toFixed(2) - 0
              : (planDetails.price - discountEarly).toFixed(2)}
          </p>
        </div>
        {/* <div className="flex justify-between py-2">
          <p className="text-gray-700">Tax</p>
          <p className="font-semibold text-gray-800">$0.00</p>
        </div> */}
        <div className="flex justify-between py-2 border-t-2 border-gray-300 pt-2">
          <p className="text-lg font-bold">Total Due</p>
          <p className="text-lg font-bold text-green-600">
            $
            {selectedPlan === "annual"
              ? (discountedPrice - 0).toFixed(2)
              : (planDetails.price - discountEarly).toFixed(2)}
          </p>
        </div>
      </div>

      <button
        type="submit"
        disabled={isButtonDisabled}
        className={`w-full py-3 font-bold rounded-lg text-white bg-green-500 hover:bg-green-600 transition-colors ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        {loading ? (
          <Loader id="spinner" className="h-6 w-6 mx-auto" />
        ) : (
          "Subscribe"
        )}
      </button>
    </form>
  );
}
