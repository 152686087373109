import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set the app element for accessibility

export default function Terms({ modalIsOpen, closeModal }) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Terms of Service"
      className="fixed inset-0 flex items-center justify-center p-4 z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full p-4 overflow-y-auto max-h-screen relative">
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 mt-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="terms-content">
          <img
            src="/babylon.png"
            alt="Logo"
            className="h-16 mb-4 mr-4 sm:mr-6 lg:mr-8"
          />
          <h1 className="text-3xl font-bold mb-2">
            User Agreement - Terms of Service
          </h1>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              1. Registration and Acceptance of Subscription
            </h2>
            <p className="mb-4">
              You agree that by clicking “Subscribe”, “Join Now”, “Sign Up” or
              similar, registering, accessing or using our services, you are
              agreeing to enter into a legally binding contract with
              Babylon2k.org (even if you are using our Services on behalf of a
              company). If you do not agree to this contract (“Contract” or
              “User Agreement”), do not click “Join Now” (or similar) and do not
              access or otherwise use any of our Services. If you wish to
              terminate this contract, at any time you can do so by closing your
              account and no longer accessing or using our Services.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">2. Changes</h2>
            <p className="mb-4">
              We may modify this Contract, based on our Privacy Policy and our
              Cookies Policy from time to time. If we make material changes to
              it, we will notify you through our Services, or by other means,
              for you to have an opportunity to review the changes before they
              become effective. We agree that changes cannot be retroactive. If
              you object/disagree to any changes, you may close your account.
              Continuing our Service after the notification about the changes
              means that you are consenting to the updated terms as of their
              effective date.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">3. Termination</h2>
            <p className="mb-4">
              You and Babylon2k.org may terminate the Contract at any time. Once
              our Contract is terminated, you will lose the right to access or
              use our Services. The following shall survive termination:
            </p>
            <ul className="list-disc pl-4">
              <li>Our rights to use and disclose your feedback;</li>
              <li>
                Members and/or Visitors’ rights to further re-share content and
                information you shared through the Services;
              </li>
              <li>See Annex, refer to section of disclaimer;</li>
              <li>
                Any amounts owed by either party prior to termination remain
                owed after termination.
              </li>
            </ul>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">4. Services Eligibility</h2>
            <p className="mb-4">
              To use the Services, you agree that: (1) you must be at least 18
              years old; (2) you will only have one account, which must be under
              your real name. Creating an account with false information is a
              violation of our terms, including accounts registered on behalf of
              others or persons under the age of 16.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">4.1 Your Account</h2>
            <p className="mb-4">
              Username and Password You will not share an account with anyone
              else and will follow our rules and the law. Members are account
              holders. You agree to:
            </p>
            <ul className="list-disc pl-4">
              <li>Use a strong password and keep it confidential;</li>
              <li>
                Not transfer any part of your account (e.g., connections);
              </li>
              <li>
                Follow the law and our BABYLON2K.ORG’s PRIVACY STATEMENT. You
                are responsible for anything that happens through your account
                unless you close it or report misuse.
              </li>
            </ul>
            As between you and others (including your employer), your account
            belongs to you. However, if the Services were purchased by another
            party for you to use (e.g., Recruiter seat bought by your employer),
            the party paying for such Service has the right to control access to
            and get reports on your use of such paid Service; however, they do
            not have rights to your personal account.
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">4.2 Payment</h2>
            <p className="mb-4">
              You’ll honor your payment obligations and you are okay with us
              storing your payment information. You understand that there may be
              fees and taxes that are added to our prices. Refunds are subject
              to our policy.
              <br />
              If you buy any of our paid Services (“Premium/Paid Services”), you
              agree to pay us the applicable fees and taxes and to additional
              terms specific to the paid Services. Failure to pay these fees
              will result in the termination of your paid Services. Also, you
              agree that:{" "}
            </p>
            <ul className="list-disc pl-4">
              <li>
                Your purchase may be subject to foreign exchange fees or
                differences in prices based on location (e.g., exchange rates).
              </li>
              <li>
                We may store and continue billing your payment method (e.g.,
                credit card) even after it has expired, to avoid interruptions
                in your Services and to use to pay other Services you may buy.
              </li>
              <li>
                If you purchase a subscription, your payment method
                automatically will be charged at the start of each subscription
                period for the fees and taxes applicable to that period. To
                avoid future charges, cancel before the renewal date. Learn how
                to cancel or suspend your Premium Services.
              </li>
              <li>
                All of your purchases of Services are subject to BABYLON2K.ORG
                refund policy.
              </li>
              <li>
                We may calculate taxes payable by you based on the billing
                information that you provide us at the time of purchase.
              </li>
            </ul>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              4.3 Notice and Messages (on the changes)
            </h2>
            <p className="mb-4">
              You will be notified in the following ways if there are changes in
              the services: Sent to the contact information you provided us
              (e.g., email, mobile number, physical address). You agree to keep
              your contact information up to date.
              <br />
              Please review your settings to control and limit messages you
              receive from us, or you’re okay with us providing notices and
              messages to you through our websites, applications, and contact
              information. If your contact information is out of date, you may
              miss out on important notices.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">4.4 Information Sharing</h2>
            <p className="mb-4">
              Our Services allow messaging and sharing of information in many
              ways, such as your profile, articles, group posts, news articles,
              job postings, messages, and email. Information and content that
              you share, or post may be seen by other Members, Visitors, or
              others (including off of the Services). Where we have made
              settings available, we will honor the choices you make about who
              can see content or information. For job searching activities, we
              default to not notifying your connections network or the public.
              So, if you apply for a job through our Service or opt to signal
              that you are interested in a job, our default is to share it only
              with the job poster.
              <br />
              We are not obligated to publish any information or content on our
              Service and can remove it with or without notice.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">5. Service Availability</h2>
            <p className="mb-4">
              We may change or end any Service or modify our prices
              prospectively.
              <br />
              We may change, suspend or discontinue any of our Services. We may
              also modify our prices effective prospectively upon reasonable
              notice to the extent allowed under the law.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">6. Disclaimer</h2>
            <p className="mb-4">
              These Terms of Use apply to www.babylon2k.org as well as any other
              affiliated sites, digital services, or applications on which a
              link to these Terms of Use appears (collectively, the
              "Babylon2k.org Website") and apply to all visitors. These Terms of
              Use and any other terms that appear on the page from which you
              were directed to these Terms of Use govern your use of the BABYLON
              Website. By accessing the BABYLON Website, you agree to be legally
              bound by the Terms of Use then in effect. Please also refer to the
              relevant additional legal information applicable to your country.
              <br />
              These Terms of Use as well as the information and materials
              contained in the BABYLON Website are subject to change at any time
              and from time to time, without notice. BABYLON may add features to
              or remove features from the BABYLON Website at any time and from
              time to time in our sole discretion. Continued access to the
              BABYLON Website following any modification in these Terms of Use
              will constitute your acceptance of the Terms of Use as modified.
              If you do not agree to be bound by these Terms of Use, do not use
              the BABYLON Website.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              7. Access Subject to Local Restrictions
            </h2>
            <p className="mb-4">
              The BABYLON Website and all information and functionalities
              contained within them are not directed at or intended for use by
              any person resident or located in any jurisdiction where
            </p>
            <ul className="list-disc pl-4">
              <li>
                the distribution of such information or functionality is
                contrary to the laws of such jurisdiction; or
              </li>
              <li>
                such distribution is prohibited without obtaining the necessary
                licenses or authorizations by the relevant branch, subsidiary or
                affiliate office of BABYLON and such licenses or authorizations
                have not been obtained.
              </li>
            </ul>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">8. Services and Products</h2>
            <p className="mb-4">
              Products and services displayed on the BABYLON Website may not be
              eligible for sale or available to residents of certain countries
              or certain categories of investors. Please consult the applicable
              terms and conditions such as sales restrictions or key specific
              notes for further information. Any materials, information or
              reports published, distributed or otherwise made available on the
              BABYLON Website is published for information purposes and is
              subject to change at any time without notice. Unless specifically
              stated otherwise, all price information is indicative only. No
              representation or warranty, either express or implied, is provided
              in relation to the accuracy, completeness or reliability of the
              materials, nor are they a complete statement of the securities,
              markets or developments referred to herein. The materials should
              not be regarded by recipients as a substitute for the exercise of
              their own judgment. Any opinions are subject to change without
              notice and may differ or be contrary to opinions expressed by
              other business areas or groups of BABYLON as a result of using
              different assumptions and criteria. BABYLON is not under any
              obligation to update or keep current the information contained
              herein nor to remove any outdated information from the BABYLON
              Website or to expressly mark it as being outdated.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">9. No Offer</h2>
            <p className="mb-4">
              All information and materials published, distributed or otherwise
              made available on the BABYLON Website are provided for
              informational purposes, for your non-commercial, personal use
              only. No information or materials published on the BABYLON Website
              constitutes a solicitation, an offer, or a recommendation to sell
              services or products, to affect any transactions, or to conclude
              any legal act of any kind whatsoever. BABYLON does not provide
              legal or tax advice through the BABYLON Website and nothing herein
              should be construed as being financial, legal, tax or other
              advice. When deciding about your tax concerns, you should seek the
              advice of a tax professional or any eligible expert in the field
              of tax.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              10. Forward Looking Statements
            </h2>
            <p className="mb-4">
              The BABYLON Website may contain statements that constitute
              "forward looking statements". While these forward-looking
              statements may represent BABYLON’s judgment and future
              expectations, several risks, uncertainties and other important
              factors could cause actual developments and results to differ
              materially from BABYLON’s expectations. BABYLON is under no
              obligation to (and expressly disclaims any such obligation to)
              update or alter its forward-looking statements whether as a result
              of new information, future events, or otherwise.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">11. No Warrant</h2>
            <p className="mb-4">
              Your use of the BABYLON Website is at your own risk. The BABYLON
              Website, together with all content, information and materials
              contained therein, is provided "as is" and "as available" ",
              without any representations or warranties of any kind. Any
              materials, information or content accessed, downloaded or
              otherwise obtained through the use of the BABYLON Website is done
              at your own risk and BABYLON is not responsible for any damage to
              your computer systems or loss of data that results from the
              download of such material. BABYLON and its affiliates expressly
              disclaimer all representations or warranties of any kind, whether
              express or implied, with respect to the BABYLON Website, and all
              materials, information, content and functionalities contained
              herein, including but not limited to warranties of title,
              non-infringement, merchantability or fitness for a particular
              purpose. Without limiting the foregoing, BABYLON does not warrant
              the accuracy, timeliness, completeness, security, reliability or
              availability of the BABYLON Website or the information or results
              obtained from use of the BABYLON Website, or that the BABYLON
              Website will be available at all times, or is virus-free or
              error-free.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">12. No Liability</h2>
            <p className="mb-4">
              To the fullest extent permitted by law, in no event shall BABYLON
              or our affiliates, or any of our directors, employees,
              contractors, service providers or agents have any liability
              whatsoever to any person for any direct or indirect loss,
              liability, cost, claim, expense or damage of any kind, whether in
              contract or in tort, including negligence, or otherwise, arising
              out of or related to the use of all or part of the BABYLON
              Website, or any links to third party websites. You are solely
              responsible for acquiring and maintaining such electronic devices
              and equipment that can handle and will allow you to access and use
              the BABYLON Website and for taking adequate and appropriate data
              security measures in accordance with good industry practice to
              protect yourself against fraud or cyber-attacks on a continuous
              basis (e.g. by using the most recent browser versions, and
              installing recommended security patches and up-to-date anti-virus
              programs and firewalls). BABYLON specifically disclaims all
              liability for any tampering with any user's computer system by
              unauthorized parties, or for losses or liabilities suffered by any
              user arising from viruses or attacks by hackers. In no event will
              BABYLON or our affiliates, or any of our officers, directors,
              employees, contractors, service providers or agents be liable for
              any incidental, special, punitive or consequential damages
              howsoever caused arising out of the use of the BABYLON Website,
              its content, or the inability to use the BABYLON Website. This
              disclaimer of liability applies to any and all damages or injury,
              including those caused by any failure of performance, error,
              omission, interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft,
              destruction, or unauthorized access, whether for breach of
              contract, tortious behavior, negligence or under any other cause
              of action.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">13. Links</h2>
            <p className="mb-4">
              The BABYLON Website may contain third party content or links to
              third party websites. These content and links are provided solely
              for your convenience and information. BABYLON has no control over,
              does not assume any responsibility for and does not make any
              warranties or representations as to, any third-party content or
              websites, including but not limited to, the accuracy, subject
              matter, quality or timeliness. BABYLON shall have no liability for
              third party content or websites or websites linking to or framing
              the BABYLON Website.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">14. Social Bookmarks</h2>
            <p className="mb-4">
              The BABYLON Website may contain social bookmarks which are
              identifiable by means of their respective buttons. Social
              bookmarks allow users of certain social media platforms to post
              links to selected BABYLON web pages on their social media accounts
              in order to bookmark them or share them with their social media
              contacts. If you use social bookmarks, you will be sending
              personal identifying data to the respective social media platform.
              Any comments or activity made by or carried out by persons using
              social bookmarks is not controlled, endorsed or monitored by
              BABYLON, and BABYLON shall not be responsible or liable for such.
              Persons who share BABYLON communications via social bookmarks are
              not authorized to speak for or represent BABYLON and their views
              and opinions must be regarded as strictly their own and not
              BABYLON's. In addition, the provisions in these Terms of Use
              relating to Links also apply to social bookmarks.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              15. Intellectual Property Rights
            </h2>
            <p className="mb-4">
              BABYLON, or the applicable third party owner, retains all right,
              title and interest (including copyrights, trademarks, patents, as
              well as any other intellectual property or other right) in all
              information and content (including all text, data, graphics and
              logos) on the BABYLON Website. Individual pages and/or sections of
              the BABYLON Website may be printed for personal or internal use
              only, and provided that such print outs retain all applicable
              copyright or other proprietary notices. All users must not,
              without limitation, modify, copy, transmit, distribute, display,
              perform, reproduce, publish, license, frame, create derivative
              works from, transfer or otherwise use in any other way, whether in
              whole or in part, any information, text, graphics, images, video
              clips, directories, databases, listings or software obtained from
              the BABYLON Website for commercial or public purposes, without the
              prior written permission of BABYLON. Systematic retrieval of
              content from the BABYLON Website to create or compile, directly or
              indirectly, a collection, compilation, database or directory
              (whether through robots, spiders, automatic devices or manual
              processes) or creating links to the BABYLON Website without the
              prior written permission from BABYLON is strictly prohibited. The
              key symbol and BABYLON are among the registered and unregistered
              trademarks of BABYLON. Other marks may be trademarks of their
              respective owners. Except as noted above, you may not use any
              trademarks of BABYLON for any purpose without the prior written
              permission of BABYLON.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              16. Electronic Communication (E-Mail)
            </h2>
            <p className="mb-4">
              The Internet is a universally accessible medium. Ordinary e-mail
              messages sent over the Internet are neither confidential nor
              secure. They may be easily accessible by third parties and may
              entail a considerable amount of danger and risk including: (a)
              lack of confidentiality (e-mails and their attachments can be read
              and/or monitored without detection); (b) manipulation or
              falsification of the sender's address or of the e-mail's (or
              attachment's) content (e.g. changing the sender's address(es) or
              details); (c) system outages and other transmission errors, which
              can cause emails and. their attachments to be delayed, mutilated,
              misrouted and deleted; (d) viruses, worms, Trojan horses etc. may
              be spread undetected by third parties and may cause considerable
              damage; and (e) interception by third parties. You are therefore
              recommended to communicate with BABYLON by using a secure channel
              if such channel is provided by BABYLON. In no event should you use
              ordinary E-mail to transmit personal or confidential information
              such as any account related data. Unless explicitly stated
              otherwise, BABYLON does not accept any orders or instructions sent
              by Email or any other electronic messaging system. BABYLON shall
              not be liable to you or anybody else for any damages incurred in
              connection with any messages sent to BABYLON using ordinary E-mail
              or any other electronic messaging system. Please see our E-Mail
              Disclosure for more information about our e-mail practices and
              policy.
            </p>
            <ul className="list-disc pl-4">
              <li>
                lack of confidentiality (e-mails and their attachments can be
                read and/or monitored without detection)
              </li>
              <li>
                manipulation or falsification of the sender's address or of the
                e-mail's (or attachment's) content (e.g. changing the sender's
                address(es) or details);{" "}
              </li>
              <li>
                system outages and other transmission errors, which can cause
                emails and. their attachments to be delayed, mutilated,
                misrouted and deleted;{" "}
              </li>
              <li>
                viruses, worms, Trojan horses etc. may be spread undetected by
                third parties and may cause considerable damage; and{" "}
              </li>
              <li>
                interception by third parties. You are therefore recommended to
                communicate with BABYLON by using a secure channel if such
                channel is provided by BABYLON. In no event should you use
                ordinary E-mail to transmit personal or confidential information
                such as any account related data. Unless explicitly stated
                otherwise, BABYLON does not accept any orders or instructions
                sent by Email or any other electronic messaging system. BABYLON
                shall not be liable to you or anybody else for any damages
                incurred in connection with any messages sent to BABYLON using
                ordinary E-mail or any other electronic messaging system. Please
                see our E-Mail Disclosure for more information about our e-mail
                practices and policy.
              </li>
            </ul>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">17. Privacy Statement</h2>
            <p className="mb-4">
              BABYLON has established a Privacy Statement, as modified from time
              to time, which explains how personal data is collected on the
              BABYLON Website and used. The Privacy Statement is part of these
              Terms of Use and is incorporated herein by reference.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">18. Severability Clause</h2>
            <p className="mb-4">
              To the extent that any provision of these Terms of Use is found by
              any competent court or authority to be invalid, unlawful or
              unenforceable in any jurisdiction, that provision shall be deemed
              to be severed from these Terms of Use but such finding shall not
              affect the validity, lawfulness or enforceability of the remainder
              of these Terms of Use in that jurisdiction, nor shall it affect
              the validity, lawfulness or enforceability of these Terms of Use
              in any other jurisdiction.
            </p>
          </div>

          <div className="terms-section">
            <h2 className="text-xl font-bold mb-2">
              19. Conflict with Additional Terms
            </h2>
            <p className="mb-4">
              Certain web pages or areas on the BABYLON Website may contain
              additional terms, conditions, disclosures and disclaimers
              (collectively, the “Additional Terms”). In the event of a conflict
              between these Terms of Use and the Additional Terms, the
              Additional Terms will govern for those web pages or areas.
            </p>
          </div>

          <button
            onClick={closeModal}
            className="mt-6 w-full bg-indigo-600 text-white py-2 rounded-lg font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
