import React from "react";
import { FaEnvelope } from "react-icons/fa";

const MailTo = ({ email, label }) => {
  const handleEmail = (email) => {
    const mailtoLink = `mailto:${email}?subject=${label}`;
    window.location.href = mailtoLink;
  };

  return (
    <div
      onClick={() => handleEmail(email)}
      className="flex items-center space-x-2 text-gray-700 cursor-pointer"
    >
      <FaEnvelope className="text-gray-500" />
      <p>{email}</p>{" "}
    </div>
  );
};

export default MailTo;
