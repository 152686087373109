import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const gSearch = ({ message, messages, index }) => {
  // console.log(message, messages, index);
  return (
    <>
      {message.role === "assistant" && index === messages.length - 1 && (
        <div className="flex items-center space-x-2 mb-2 text-sm text-gray-600">
          <FontAwesomeIcon icon={faSearch} className="text-blue-500" />
          <span
            className="relative inline-block bg-clip-text text-transparent"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #454343, #b3b1b1, #999999)",
              backgroundSize: "200% auto",
              animation: "shine 3s linear infinite",
            }}
          >
            Scraping information from the Web
          </span>
        </div>
      )}
    </>
  );
};

export default gSearch;
