import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TawkTo = () => {
  const location = useLocation();

  useEffect(() => {
    const scriptId = "tawk-to-script";
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      existingScript.remove();
    }

    if (
      location.pathname !== "/app" &&
      location.pathname !== "/app/" &&
      location.pathname !== "/user-page" &&
      location.pathname !== "/signin"
    ) {
      const s1 = document.createElement("script");
      s1.id = scriptId;
      s1.async = true;
      s1.src = "https://embed.tawk.to/5f312584ed9d9d2627098feb/default";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      document.body.appendChild(s1);
    } else {
      window.Tawk_API?.hideWidget();
    }
  }, [location.pathname]);

  return null;
};

export default TawkTo;
