import { useRef, useState } from "react";
import api from "../../../utils/api";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const fetchUserLevel = async (token, setUserLevel) => {
  try {
    const response = await api.get(`/user/level`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setUserLevel(response.data.user_level);
  } catch (error) {
    console.error("Error fetching user level:", error);
  }
};

export const fetchChatHistory = async (token, setThreadId, setRefresh) => {
  try {
    const response = await api.get(`/chat/history/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const history = response.data;
    if (history?.length > 0) {
      setThreadId(history[0].thread_id);
      sessionStorage.setItem("threadId", history[0].thread_id);
    } else {
      const newThread = await api.get(`/chat/new/thread`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setThreadId(newThread.data.thread_id);
      sessionStorage.setItem("threadId", newThread.data.thread_id.toString());
      setRefresh((prev) => !prev);
    }
  } catch (error) {
    console.error("Error fetching chat history:", error);
  }
};

export const useSendMessage = (
  token,
  threadId,
  setMessages,
  setIsLoading,
  setHasError,
  setWait,
  setDidSearch,
  setLinkSearch,
  setData,
  setNewMessage,

  runId,

  setRunId,
  messageId,
  setMessageId
) => {
  const eventSourceRef = useRef(null);
  const handleSendMessage = async (
    message,
    file,
    sender = "user",
    tokenCount
  ) => {
    const trimmedMessage = message.trim();
    setNewMessage("");
    const selectedFile = file?.current?.files[0];

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: sender,
        text_value: trimmedMessage,
        file: file ? file.name : null,
      },
      { role: "assistant", text_value: "" },
    ]);
    setIsLoading(true);
    setWait(true);
    setLinkSearch(false);

    let formData = new FormData();
    formData.append("thread_id", threadId);
    formData.append("message", trimmedMessage);
    if (file) {
      formData.append("file", selectedFile);
    }

    try {
      await api.post(`/chat/response/assistant`, formData);
      eventSourceRef.current = new EventSource(
        `https://api.babylon2k.org/chat/stream/v2?thread_id=${threadId}&token=${token}`
      );

      eventSourceRef.current.onopen = async () => {
        await api.post(
          `/chat/update/token?chat_token=${tokenCount}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      };
      let accumulatedText = "";
      let timeoutId;

      eventSourceRef.current.onmessage = (event) => {
        const eventData = JSON.parse(event.data);

        if (["text_created", "text_delta"].includes(eventData.event)) {
          accumulatedText += eventData.data;

          setDidSearch(false);
          setIsLoading(false);
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setWait(false);
          }, 300);
        } else if (eventData.event === "run_id") {
          setRunId(eventData.data);
        } else if (eventData.event === "message_id") {
          console.log(eventData.data);
          setMessageId(eventData.data);
        } else if (eventData.event === "message_after_search") {
          accumulatedText += eventData.data;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setDidSearch(false);
            setWait(false);
            setIsLoading(false);
          }, 300);
        } else if (eventData.event === "tool_call_created") {
          setDidSearch(true);
          setIsLoading(false);
        } else if (eventData.event === "tool_call_done") {
          setDidSearch(false);
          setLinkSearch(true);
          setData(eventData.data);
        }
        const cleanedText = accumulatedText.replace(/【[^】]*】/g, "").trim();
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            text_value: cleanedText,
          };
          localStorage.setItem(
            `messages_${threadId}`,
            JSON.stringify(updatedMessages)
          );

          return updatedMessages;
        });
      };
      // setHasError(true);

      eventSourceRef.current.onerror = () => {
        eventSourceRef.current.close();
        setIsLoading(false);
        setHasError(true);
      };
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  return { handleSendMessage };
};

export const errorTool = ({
  hasError,
  setHasError,
  token,
  messageId,
  threadId,
  messages,
  setMessages,
  handleSendMessage,
}) => {
  // const eventSourceRef = useRef(null);

  const handleRegenerate = async () => {
    setHasError(false);

    try {
      const response = await api.delete(
        `/chat/stream/delete/${threadId}/message/${messageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessages((prevMessages) => {
        const newMessages = prevMessages.slice(0, -1);
        return newMessages;
      });

      const lastUserMessage = messages
        .slice()
        .reverse()
        .find((msg) => msg.role === "user");

      // if (lastUserMessage) {
      //   handleSendMessage(lastUserMessage.text_value, null, "user", 0);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    hasError && (
      <div className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-red-600 text-white p-4 rounded-lg shadow-lg flex items-center space-x-3 z-50 ">
        {" "}
        <FontAwesomeIcon icon={faTimesCircle} className="text-white" />
        <span>Error occurred!</span>
        <button
          onClick={handleRegenerate}
          className="bg-white text-red-600 px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-200"
        >
          Retry
        </button>
      </div>
    )
  );
};
